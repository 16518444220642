import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import useStyles from "../helpers/styles";
import config from "../config";

export default function FormWrapper(props) {
  const classes = useStyles();

  if (!config.position || config.position === "center") {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {props.children}
      </Container>
    );
  } else {
    return (
      <Grid container component="main" className={classes.root} spacing={0}>
        <CssBaseline />
        <Grid item xs={false} sm={6} md={8} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.formPanel}
          component={Paper}
          elevation={6}
          square
        >
          {props.children}
        </Grid>
      </Grid>
    );
  }
}

FormWrapper.propTypes = {
  children: PropTypes.node,
};
