import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root.MuiButton-containedPrimary": {
      backgroundColor: "#3fb760",
      color: "#ffffff",

      "&:hover": { backgroundColor: "#7ccd90" },
    },
    "button.MuiButtonBase-root.MuiButton-root": {
      padding: "8px",
    },
    "form .submit-btn, form .cancel-btn": {
      margin: "15px 0",
    },
    "a.MuiTypography-root.MuiLink-root": {
      fontWeight: 500,
    },
  },
  root: {
    height: "100vh",
    maxWidth: "1600px",
    margin: "auto",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    //border: '1px blue solid'
  },
  paper: {
    //marginTop: theme.spacing(8),
    margin: theme.spacing(8, 4),
    //margin: theme.spacing(8, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //maxWidth: '400px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  logo: {
    width: "160px",
    height: "50px",
    cursor: "pointer",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  box: {
    //border: '1px red solid'
  },
  formPanel: {
    maxWidth: "400px",
    minWidth: "300px",
    //border: '1px red solid',
    //margin: 'auto'
    marginLeft: "auto",
    marginRight: "auto",
  },
  primary: {
    color: "#3fb760",
    fontWeight: 500,
    textDecoration: "none",
  },
}));
