import config from "../config";

// It will set the dest in the session storage
const params =
  typeof window !== "undefined"
    ? new URLSearchParams(window.location.search)
    : null;
const dest = (params && params.get("dest")) || null;
const app = (params && params.get("app")) || "yogi";

const clearAuthParameters = (url) => {
  if (typeof window !== "undefined") {
    const decodedUrl = window.decodeURIComponent(url);
    const arr1 = decodedUrl.split("?");
    const paramsArr = (arr1.length > 1 && arr1[1].split("&")) || [];
    const queryString = paramsArr.reduce((query, param) => {
      if (param.indexOf("jwtToken") === -1 && param.indexOf("logout") === -1) {
        query += `${query && "&"}${param}`;
      }
      return query;
    }, "");

    return `${arr1[0]}?${queryString}`;
  }

  return url;
};

if (typeof window !== "undefined") {
  if (dest) {
    window.sessionStorage.setItem("dest", clearAuthParameters(dest));
  } else if (!window.sessionStorage.getItem("dest")) {
    window.sessionStorage.setItem("dest", config[app].signInPath);
  }
}
